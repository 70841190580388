// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookiepolicy-tsx": () => import("./../../../src/pages/cookiepolicy.tsx" /* webpackChunkName: "component---src-pages-cookiepolicy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-itemopener-tsx": () => import("./../../../src/pages/itemopener.tsx" /* webpackChunkName: "component---src-pages-itemopener-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-termsofservice-tsx": () => import("./../../../src/pages/termsofservice.tsx" /* webpackChunkName: "component---src-pages-termsofservice-tsx" */)
}

